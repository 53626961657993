import React, { useState } from "react"
import SEO from "./SEO"
import { Link } from "gatsby"
import { motion } from "framer-motion"
import logoImage from "../images/JLF_LogoLong_Web.png"
import logoIcon from "../images/jlf-icon-white.svg"
// import iconFacebook from "../images/icon-facebook.svg"
import iconInstagram from "../images/icon-instagram.svg"
import iconTwitter from "../images/icon-twitter.svg"
// import iconLinkedin from "../images/icon-linkedin.svg"

const Layout = ({ children }) => {
  let toggleMenu = () => {
    console.log('Toggle from ', navbarOpen)
    setNavbarOpen(!navbarOpen)
  }

  const [navbarOpen, setNavbarOpen] = useState(false)

  return (
    <>
      <SEO />
      <div className="flex flex-col min-h-screen bg-white font-body subpixel-antialiased">
        <header className="px-6 lg:px-0 md:mx-auto mt-6 mb-8 lg:w-full flex flex-col lg:flex-row justify-between items-center md:items-start max-w-5xl">
          <div className="flex w-full justify-between items-center">
            <Link to="/">
              <img
                className="h-20 w-auto"
                src={logoImage}
                alt="Joe Little Foundation"
              />
            </Link>

            <button onClick={toggleMenu} className="p-3 md:hidden rounded border border-cornflower-500 text-cornflower-500" type="button">
              <svg xmlns="http://www.w3.org/2000/svg" className={`h-6 w-6 ${navbarOpen ? "hidden" : "block"}`} fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
              </svg>

              <svg xmlns="http://www.w3.org/2000/svg" className={`h-6 w-6 ${navbarOpen ? "block" : "hidden"}`} fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
{/* 
          <div className={`bg-white ${navbarOpen ? "text-scarlet-500" : ""}`}>
            Boop {navbarOpen ? "Close" : "Open"}
          </div> */}

          <div className={`flex flex-col md:items-end overflow-hidden md:overflow-visible md:h-auto transition duration-200 ease-out fixed md:relative bg-cornflower-300 md:bg-transparent p-6 top-0 left-0 bottom-0 z-10 ${navbarOpen ? "opacity-100" : "opacity-0 pointer-events-none"} md:pointer-events-auto md:opacity-100`}>
            <img
                className="h-16 w-auto mb-5 md:hidden"
                src={logoIcon}
                alt="Joe Little Foundation"
              />

            <nav className="flex flex-col font-display text-blue-400 text-2xl md:items-center md:text-lg md:flex-row font-bold space-y-6 md:space-y-0 md:space-x-6 mb-6 md:mb-2 leading-none whitespace-nowrap">
              <Link to="https://givebutter.com/GiveForJoe" className="bg-scarlet-500 text-white hover:bg-scarlet-600 px-4 py-1 rounded-full transition-colors ease-in-out duration-150">
                Give Like Joe
              </Link>

              <Link to="/about/" className="hover:text-scarlet-500 transition-colors ease-in-out duration-150" activeClassName="text-scarlet-500">
                Joe Little
              </Link>

              <Link to="/mission/" className="hover:text-scarlet-500 transition-colors ease-in-out duration-150" activeClassName="text-scarlet-500">
                Mission
              </Link>

              <Link to="/athletics/" className="hover:text-scarlet-500 transition-colors ease-in-out duration-150" activeClassName="text-scarlet-500">
                Athletics
              </Link>

              <Link to="/board/" className="hover:text-scarlet-500 transition-colors ease-in-out duration-150" activeClassName="text-scarlet-500">
                Board Members
              </Link>

              <Link to="/contact/" className="hover:text-scarlet-500 transition-colors ease-in-out duration-150" activeClassName="text-scarlet-500">
                Contact Us
              </Link>
            </nav>

            <nav className="flex space-x-6 md:space-x-2">
              <a href="https://www.instagram.com/joelittleathletics/" target="_blank" rel="noreferrer" className="bg-cornflower-500 p-2 rounded-full">
                <img
                  className="w-4 h-4"
                  src={iconInstagram}
                  alt="Joe Little Foundation on Instagram"
                  />
              </a>

              <a href="https://twitter.com/AthleticsJoe" target="_blank" rel="noreferrer" className="bg-cornflower-500 p-2 rounded-full">
                <img
                  className="w-4 h-4"
                  src={iconTwitter}
                  alt="Joe Little Foundation on Twitter"
                  />
              </a>
            </nav>
          </div>
        </header>

        <motion.main
          className="flex-grow"
          initial={{
            opacity: 0,
            x: -100
          }}
          animate={{
            opacity: 1,
            x: 0
          }}
          exit={{
            opacity: 0,
            x: 100
          }}
          transition={{
            type: "spring",
            mass: 0.35,
            stiffness: 75,
            duration: 0.25
          }}
        >
          {children}
        </motion.main>

        <footer className="py-6 mt-24 text-center text-xs bg-gray-500">
          <div className="flex justify-between items-center px-6 md:px-0 max-w-4xl mx-auto">
            <img
                className="h-16 w-auto mb-5"
                src={logoIcon}
                alt="Joe Little Foundation"
              />

            <div className="">
              <nav className="flex space-x-6 md:space-x-2">
                <a href="https://www.instagram.com/joelittleathletics/" target="_blank" rel="noreferrer" className="bg-blue-100 p-2 rounded-full">
                  <img
                    className="w-4 h-4"
                    src={iconInstagram}
                    alt="Joe Little Foundation on Instagram"
                    />
                </a>

                <a href="https://twitter.com/AthleticsJoe" target="_blank" rel="noreferrer" className="bg-blue-100 p-2 rounded-full">
                  <img
                    className="w-4 h-4"
                    src={iconTwitter}
                    alt="Joe Little Foundation on Twitter"
                    />
                </a>
              </nav>
            </div>
          </div>

          <div className="flex justify-between items-center px-6 md:px-0 max-w-4xl mx-auto">
            <div className="text-gray-400">&copy; Joe Little Foundation</div>

            <div className="text-gray-400">Cheered on by <a href="https://www.layersdm.com/" className="text-gray-300 hover:text-gray-200" target="_blank" rel="noreferrer">Layers Digital Marketing</a></div>
          </div>
        </footer>
      </div>
    </>
  )
}

export default Layout
